import Box from './Box';
import Typography from './Typography';

const Fallback = ({ title, description, image, ...properties }) => (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mx={'auto'} {...properties}>
        <Box as={'img'} src={image} width={'100%'} maxWidth={720} mx={'auto'} />

        <Box mx={'auto'} mt={40}>
            <Typography fontSize={'lg'} fontWeight={'bold'} textAlign={'center'}>
                {title}
            </Typography>

            <Typography className={'mt-2'} color={'text.variant'} textAlign={'center'}>
                {description}
            </Typography>
        </Box>
    </Box>
);

export default Fallback;
